import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const LS_LAYOUT_CONFIG_KEY = 'eSalon.layout';

export interface ICrumb {
  label: string;
  path: string;
}

export interface ILayoutConfig {
  mode: 'desktop' | 'web';
  showNavbar?: boolean;
}

const defaultConf: ILayoutConfig = {
  mode: 'web',
  showNavbar: true
};

@Injectable({ providedIn: 'root' })
export class LayoutService implements ILayoutConfig {

  private _config: ILayoutConfig = defaultConf;

  private readonly _crumbs$ = new BehaviorSubject<ICrumb[]>([]);
  private readonly _pageLoading$ = new BehaviorSubject<boolean>(false);
  private readonly _pageTitle$ = new BehaviorSubject<string | undefined>(undefined);

  public readonly crumbs$ = this._crumbs$.asObservable();
  public readonly hasCrumbs$ = this._crumbs$.pipe(filter(c => !!c.length));
  public readonly pageLoading$ = this._pageLoading$.asObservable();
  public readonly pageTitle$ = this._pageTitle$.asObservable();
  public readonly sidenavClose$ = new Subject<void>();

  get mode() { return this._config.mode; }
  set mode(value: 'desktop' | 'web') {
    if (this._config.mode === value) { return; }
    this._config.mode = value;
    this._saveConfig();
    this._applyConfig();
  }

  get showNavbar() { return this._config.showNavbar; }
  set showNavbar(value: boolean) {
    if (this._config.showNavbar === value) { return; }
    this._config.showNavbar = value;
    this._saveConfig();
    this._applyConfig();
  }

  get showPageTitle(): boolean {
    if (this._pageTitle$.getValue()) { return true; }
    if (this._crumbs$.getValue().length) { return true; }
    return false;
  }

  constructor(
  ) {
    this._loadConfig();
    this._applyConfig();
  }

  private _applyConfig() {
    document.documentElement.setAttribute('data-mode', this.mode);
    document.documentElement.setAttribute('data-layout', this.showNavbar ? '' : 'nonav');
  }

  private _loadConfig() {
    this._config = { ...defaultConf, ...JSON.parse(localStorage.getItem(LS_LAYOUT_CONFIG_KEY)) };
    Object.assign(this, this._config);
  }

  private _saveConfig() {
    localStorage.setItem(LS_LAYOUT_CONFIG_KEY, JSON.stringify(this._config));
  }

  hidePageLoading() {
    setTimeout(() => {
      this._pageLoading$.next(false);
    }, 0);
  }

  setCrumbs(crumbs: ICrumb[]) {
    setTimeout(() => {
      if (crumbs.length === 0) {
        this._crumbs$.next([]);
      } else {
        this._crumbs$.next([{ label: 'eSalon', path: '/appointments' }, ...crumbs]);
      }
    }, 0);
  }

  setDesktopMode() {
    this.mode = 'desktop';
    this.showNavbar = false;
  }

  setPageTitle(title: string) {
    setTimeout(() => {
      this._pageTitle$.next(title);
    }, 0);
  }

  showPageLoading() {
    setTimeout(() => {
      this._pageLoading$.next(true);
    }, 0);
  }

}
