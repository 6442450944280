import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(
    private _authService: AuthService,
    private _logger: NGXLogger
  ) { }

  private addToken(request: HttpRequest<any>, authToken: string): HttpRequest<any> {
    return request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (request.url.endsWith('/api/auth/logout')) {
      this._logger.debug('AuthInterceptor | handle401Error | logout');
      return of(null);
    }

    if (request.url.endsWith('/api/auth/refresh')) {
      this._logger.debug('AuthInterceptor | handle401Error | refresh');
      this._authService.logout()
        .pipe(take(1))
        .subscribe(() => {
          this._logger.debug('AuthInterceptor | handle401Error | refresh | logout');
        });

      return of(null);
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._authService.refreshAuthToken()
        .pipe(
          switchMap(tokens => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(tokens.authToken);
            return next.handle(this.addToken(request, tokens.authToken));
          })
        );
    }
    else
    {
      return this.refreshTokenSubject.pipe(
        filter(tokens => tokens != null),
        take(1),
        switchMap(authToken => {
          return next.handle(this.addToken(request, authToken));
        })
      );
    }
  }

  private isSameOriginUrl(req: any): boolean {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this._authService.getAuthToken();

    if (!!authToken && this.isSameOriginUrl(request)) {
      request = this.addToken(request, authToken);
    }

    return next.handle(request)
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, error);
        } else {
          return throwError(error);
        }
      }));
  }
}
