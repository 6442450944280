import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SignalRService } from 'src/app/signalr/signalr.service';
import { INotification } from '../models/notification.model';
import { BranchesService } from 'src/app/branches/branches.service';
import { NGXLogger } from 'ngx-logger';
import { SwUpdate, VersionInstallationFailedEvent, VersionReadyEvent } from '@angular/service-worker';
import { AlertsService } from 'src/shared/alerts/alerts.service';
import { ToastService } from 'src/shared/toast/toast.service';

@Injectable({ providedIn: 'root' })
export class NotificationsService {

  private _oldest = new Date();

  private readonly _hasMore$ = new BehaviorSubject<boolean>(true);
  private readonly _hasNewNotifications$ = new BehaviorSubject<boolean>(false);

  private readonly _notifications$ = new BehaviorSubject<INotification[]>([]);

  public readonly hasMore$ = this._hasMore$.asObservable();
  public readonly hasNewNotifications$ = this._hasNewNotifications$.asObservable();
  public readonly notifications$ = this._notifications$.asObservable();

  public updateReady = false;

  constructor(
    @Inject('BASE_URL') private _baseUrl: string,
    private readonly _branchService: BranchesService,
    private readonly _http: HttpClient,
    private readonly _logger: NGXLogger,
    private readonly _signalR: SignalRService,
    private readonly _swal: AlertsService,
    private readonly _swUpdates: SwUpdate,
    private readonly _toastr: ToastService
  ) {
    this._bindBranch();
    this._bindSignalR();
    this._bindSwUpdates();
    this._bindUpdateCheck();
  }

  private _addNotification(notification: INotification, addToEnd: boolean = true): void {
    const notifications = this._notifications$.getValue();
    if (notifications.some(n => n.notificationRef === notification.notificationRef)) {
      return;
    }

    if (addToEnd) {
      this._notifications$.next([...notifications, notification]);
    }
    else
    {
      this._notifications$.next([notification, ...notifications]);
    }

    if (!notification.dismissedAt) {
      this._hasNewNotifications$.next(true);
    }
  }

  private _bindBranch() {
    this._branchService.branch$
      .subscribe((branch) => {
        this._notifications$.next([]);

        if (!!branch) {
          this._getNotifications().subscribe();
        }
      });
  }

  private _bindSignalR() {
    this._signalR.notificationRead$
      .subscribe((notificationRef: number) => {
        const notifications = this._notifications$.getValue();
        const notification = notifications.find(n => n.notificationRef === notificationRef);
        if (!!notification) {
          notification.dismissedAt = new Date();
          this._notifications$.next(notifications);
        }
      });

    this._signalR.notificationAllRead$
      .subscribe(() => {
        const notifications = this._notifications$.getValue();
        notifications.forEach(notification => {
          if (!notification.dismissedAt) { notification.dismissedAt = new Date(); }
        });
        this._notifications$.next(notifications);
      });

    this._signalR.notificationPosted$
      .subscribe((notification: INotification) => {
        if (notification.priority >= 0) {
          this._addNotification(notification, false);
        }
      });
  }

  private _bindSwUpdates() {
    this._swUpdates.versionUpdates
      .subscribe(evt => {
        switch(evt.type) {
          case 'VERSION_DETECTED':
            this._logger.info(`swUpdates downloading new version ${evt.version.hash}`);
            break;

          case 'VERSION_READY':
            this._updateReady(evt);
            break;

          case 'VERSION_INSTALLATION_FAILED':
            this._updateFailed(evt);
            break;
        }
      });

    this._swUpdates.unrecoverable
      .subscribe(evt => {
        this._logger.error('[NotificationsService].[swUpdates] unrecoverable error', evt);
        this._swal.danger('An error occurred that we cannot recover from. Please refresh the page.', 'Unrecoverable Error');
      });
  }

  private _bindUpdateCheck() {
    interval(60 * 60 * 1000)
      .subscribe(async () => {
        try {
          await this._swUpdates.checkForUpdate();
        }
        catch (err) {
          this._logger.error('[NotificationsService].[updateCheck]', err);
        }
      });
  }

  private _getNotifications(before?: Date): Observable<INotification[]> {
    return this._http.get<INotification[]>(`${this._baseUrl}api/notifications?before=${before?.toISOString() ?? ''}`)
      .pipe(
        catchError(err => {
          this._logger.error('[NotificationsService].[getNotifications]', err);
          return of([]);
        }),
        map(notifications => notifications.map((n: INotification) => {
          n.createdAt = new Date(n.createdAt);
          n.dismissedAt = n.dismissedAt ? new Date(n.dismissedAt) : null;

          if (n.createdAt < this._oldest) {
            this._oldest = n.createdAt;
          }

          return n;
        })),
        tap(notifications => {
          this._logger.debug('[NotificationsService].[getNotifications]', notifications);

          notifications.forEach(notification => {
            this._addNotification(notification);
          });

          this._hasMore$.next(notifications.length > 0);
        })
      );
  }

  private _updateFailed(evt: VersionInstallationFailedEvent) {
    this._logger.error(`swUpdates failed to install version ${evt.version.hash}: ${evt.error}`);

    this._swal.danger('Please refresh the page.', 'App update failed!');
  }

  private _updateReady(evt: VersionReadyEvent) {
    this._logger.info(`swUpdates current app version ${evt.currentVersion.hash}`);
    this._logger.info(`swUpdates new version ready ${evt.latestVersion.hash}`);

    this._toastr.info('App update available.');

    setTimeout(() => {
      this._addNotification({
        createdAt: new Date(),
        dismissedAt: null,
        message: 'App update available.',
        notificationRef: new Date().valueOf() * -1,
        priority: 5,
        objectType: 'Update',
        objectRef: 1
      }, false);

      this.updateReady = true;
    }, 1000);
  }

  loadMore(): Observable<INotification[]> {
    return this._getNotifications(this._oldest);
  }

  markAllAsRead(): Observable<void> {
    return this._http.post<void>(`${this._baseUrl}api/notifications/dismiss-all`, { })
      .pipe(
        tap(() => {
          const notifications = this._notifications$.getValue();
          notifications.forEach(notification => {
            if (!notification.dismissedAt) { notification.dismissedAt = new Date(); }
          });
          this._notifications$.next(notifications);
          this._signalR.markNotificationAllRead();
        })
      );
  }

  markAsRead(notification: INotification): Observable<void> {
    return this._http.post<void>(`${this._baseUrl}api/notifications/${notification.notificationRef}/dismiss`, { })
      .pipe(
        tap(() => {
          notification.dismissedAt = new Date();
          this._signalR.markNotificationAsRead(notification.notificationRef);
        })
      );
  }

  markAsSeen() {
    this._hasNewNotifications$.next(false);
  }

}
